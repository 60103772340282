import React from 'react';
import {useState, useEffect} from 'react';
import '../../App.css';
import HomePage from '../HomePage';
import Reviews from '../Reviews.js';
import Navbar from '../Navbar.js';

function Home() {
    return (    
      <div className="home">
        <HomePage />
        <footer>
        <Navbar page={"home"} footer={true}/>
        </footer>
      </div>
    )
}

export default Home;
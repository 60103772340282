import React, { useState, useEffect, useRef } from "react";
import {useParams} from "react-router-dom";
import "../App.css";
import "./Room.css";
import "./LotteryPage.css";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Countdown from "react-countdown";
import { doc, getDoc, updateDoc, getFirestore, increment, onSnapshot, arrayUnion} from "firebase/firestore"; 
import {
  Connection,
  Transaction,
  clusterApiUrl,
  SystemProgram,
} from "@solana/web3.js";
import Navbar from "./Navbar.js";
import Loader from "react-loader-spinner";
import moment from "moment";
import Slider from "react-slick";
import ReactCodeInput from 'react-verification-code-input';
import ConfettiCanvas from 'react-confetti-canvas';
import ticket_example from "../images/ticket-example.jpg"

function LotteryPage (props) {

  const db = getFirestore();

  const [connected, setConnected] = useState(false);
  const [logs, setLogs] = useState([""]);
  const [winners, setWinners] = useState([{name: null, address: null}]);
  const [resultDate, setResultDate] = useState("");
  const [userName, setUserName] = useState("");
  const [reserveDisabled, setReserveDisabled] = useState(false);
  const [dropNum, setDropNum] = useState(null);
  const [loading, setLoading] = useState(false);
  const [participants, setParticipants] = useState([{name: null, address: null, ticketNumber: null}]);  
  const [registered, setRegistered] = useState(false);
  const [sendAddress, setSendAddress] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [countdownComplete, setCountdownComplete] = useState(false);
  const [pool, setPool] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [ticketNumber, setTicketNumber] = useState(0);

  useEffect(() => {  
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    // onsnapshot runs once then listens to changes
    // Updates room in real time. Updates whole page's displayed info correctly.    
    const unsubscribe = onSnapshot(
      doc(db, "ReleaseDate", "Lottery"), // get last drop in collection 
      { includeMetadataChanges: true }, 
      (doc) => {        
        if (doc.exists()) { 
          if (doc.data().winners) {                 
            setWinners(doc.data().winners);            
          } else {
            setWinners({name: null, address: null});
          }          
          let docParticipants = doc.data().participants;
          setParticipants(docParticipants);
          setPool(doc.data().poolMoney);
          setRemaining(doc.data().ticketsRemaining)
        }          
      });
      
      // unsubscribe from listener 
      return () => unsubscribe();
  }, [dropNum])

  // get drop number and result date from firebase
  getDoc(doc(db, "ReleaseDate/Lottery")).then((docSnap) => {
      const dropDate = docSnap.data().nextDropDate.toDate()                            
      setResultDate(dropDate);
      setDropNum(docSnap.data().dropNum); // current drop number
      setSendAddress(docSnap.data().sendAddress); 
    })        

  const NETWORK = clusterApiUrl("mainnet-beta");
  const addLog = (log: string) => setLogs([...logs, log]);
  const connection = new Connection(NETWORK);
  //const provider = getProvider();  

  async function finishReservation() {
    if (userName.trim() === "") {
      alert("Please enter a username for this room");
    } else if (userName.trim().length > 20) {
      alert("Username must be 20 characters or less")
    } else {
      alert("You have been successfully entered into this drop! Now you just have to wait for your NFT to be verified by our team.")
    }
  }

  const getProvider = (): PhantomProvider | undefined => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    } else if ("solflare" in window) {
      const provider = window.solflare;
      if (provider.isSolflare) {
        return provider;
      }
    }
    // window.open("https://phantom.app/", "_blank");
  };

  const createTransferTransaction = async () => {
    if (!getProvider().publicKey) {
      return;
    }
    let transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: getProvider().publicKey,
        toPubkey: sendAddress, //provider.publicKey,
        // 1 lamport is 0.000000001 sol, 1000000 lamports is 0.16 cents
        lamports: 100000000
      })
    );
    transaction.feePayer = getProvider().publicKey;
    addLog("Getting recent blockhash");
    transaction.recentBlockhash = (
      await connection.getRecentBlockhash()
    ).blockhash;
    return transaction;
  };

  const sendTransaction = async () => {        
    const transaction = await createTransferTransaction();

    if (transaction) {
      try {

        setLoading(true);
        let signed = await getProvider().signTransaction(transaction);
        // addLog("Got signature, submitting transaction");
        let signature = await connection.sendRawTransaction(signed.serialize());
        // addLog(
        //   "Submitted transaction " + signature + ", awaiting confirmation"
        // );
        
        //Confirming transaction.        
        console.log(ticketNumber)
        await connection.confirmTransaction(signature).then(() => {          
          setLoading(false);
          // when entry fee is confirmed, write wallet address to firestore
          updateDoc(doc(db, "ReleaseDate", "Lottery"), {
            participants: arrayUnion({name: userName, address: getProvider().publicKey.toBase58(), ticketNumber: ticketNumber}),
            numParticipants: increment(1) // increment number of people in the lottery
          }, {merge: true}).then(() => {
            setRegistered(true);
          }).catch((err) => {
            console.log(err)
          })
        }).catch(() => {
          setLoading(false);
        });        
        
        console.log("Wallet Address: ", getProvider().publicKey.toBase58())
        // addLog("Transaction " + signature + " confirmed");
        // transaction confirmed - add the wallet address to firestore room 
      } catch (err) {
        console.warn(err);
        alert("Transaction Failed.")
        setLoading(false);
      }
    }
  };

  const mintTicket = () => {
    if (userName.trim() === "") {
      alert("Please enter a username");
    } else if (userName.trim().length > 20) {
      alert("Username must be 20 characters or less")
    } else if (ticketNumber.trim() == null) {
      alert("Enter a ticket number")
    } else {
      console.log(sendAddress)
      if (window.solana.isConnected) {
        sendTransaction();
      } else {
        //If provider is phantom, log them in to wallet, or send them to phantom website if they don't have an account.
        if ("solana" in window) {
          const provider = window.solana;
          if (provider.isPhantom) {
            //Connects to Phantom wallet.
            window.solana.connect();
            //window.solana.connect({ onlyIfTrusted: true });
            window.solana.on("connect", () => sendTransaction());
          } else {
            window.open("https://phantom.app/", "_blank");
          }
        } else if ("solflare" in window) {
          const provider = window.solflare;
          if (provider.isSolflare) {
            //Connects to Solflare wallet.
            window.solflare.connect();
            window.solflare.on("connect", () => sendTransaction());
          } else {
            window.open("https://solflare.com", "_blank");
          }          
        }
      }
    }
  };

  return (
    <div className="lottery-page">
      <Navbar page={"lottery"}/>            
      <h1 className="room_title">
      Lottery Collection
      </h1>
      {winners.length <= 1 ? 
      <>

      <section className="room-contents">

        <div className="reg-timeout">
        <h3>TICKETS REMAINING:</h3>
        <h2 className="countdown">{remaining}/100</h2>    
        </div>

        <div className="mint_price">
        <h3>MINT PRICE:</h3>
        <h2 className="countdown">0.1 SOL</h2>    
        </div>

        <h3>POT:</h3>
        <h2 className="countdown">{pool} Sol</h2>

        <h3>NEXT 10 WINNERS:</h3>
        {countdownComplete === true ?
        <Loader type="TailSpin" color="#00ffa3" height={100} width={100}/>                
        :
        <Countdown className="countdown" date={new Date(resultDate)} onComplete={() => setCountdownComplete(true)} />}   


        <div className="description-box">
          <h2 className="own_solana_n">Welcome to the Solswap Lottery Collection</h2>
          <h3>Benefits include: </h3>
          <ul className="list">
            <li className="list_item">
              A chance to win part of this month's pool of SOL
            </li>
            <li className="list_item">
              Unlimited free Solswap room entries
            </li>
            <li className="list_item">
              You can sell your NFT on Solanart
            </li>
            <li className="list_item">
              You can burn your NFT in return for 0.07 SOL (70% Floor price!)
            </li>
          </ul>
          <h4 className="green_colour">The first 30 Minters will receive an additional NFT with a random number!</h4>
        </div>

        <li className="note">
              Your personalised Lottery Tickets will be sent out every day at 7pm UTC.
        </li>
        
        <div className="inputs">
          {isMobile === true ? 
          <h3 className="mobile-room-message">You currently cannot reserve a spot on mobile, as the Phantom wallet extension is not available on iOS or Android</h3> 
          : registered ? (
            <div className="registration-complete">
              <h1>Congratulations!</h1>
              <h3>You have successfully minted a Solswap lottery ticket!</h3>
            </div>
          )
          : 
            <>
            <div className="form-item"><span className="form-number">1</span>
            <p>Enter a username</p>
              <input minLength="2" maxLength="20" type="text" title="Must be 2-20 characters long" onChange={e => setUserName(e.target.value)} className="form-input" placeholder="Username"></input>      
            </div>
            <div className="form-item"><span className="form-number">2</span>
            <p>Choose your lottery digits</p>
            <ReactCodeInput autoFocus={false} type={"number"} fields={6} onChange={(e)=>setTicketNumber(e)} className={"digits-input"} />
            </div>
            <div className="form-item"><span className="form-number">3</span>
            <p>Mint your Lottery Ticket for 0.1 SOL</p>
            <button className="navbar-button" style={{display: "inline-block"}} onClick={()=>mintTicket()}>Mint</button>
            {/* <img src={ticket_example}/> */}
            </div>
            </>
            }
        
        </div>
        
        {(participants.length === 1 && participants[0].name === null || participants.length === 0) ? 
        <h3 className="no-participants">No participants yet</h3>
        : 
        <>
             <h2 className="participants-header">COMMUNITY</h2>
             <div className="participants-grid">              
                <div className="grid-header left-header">MINTER Nº</div>
                <div className="grid-header">USERNAME</div>
                <div className="grid-header">WALLET ADDRESS</div>
                <div className="grid-header right-header">TICKET NUMBER(S)</div>                 
                {participants && participants.map((participant, i) => {            
                  return(
                  <>
                  <div className={i == participants.length -1 ? "grid-item left-item" : "grid-item"}><p>{i+1}</p></div> {/**to be changed to participantNumber from firebase */}
                  <div className="grid-item"><p>{participant.name}</p></div>
                  <div className="grid-item"><p>{participant.address}</p></div>
                  <div className={i == participants.length -1 ? "grid-item right-item" : "grid-item"}><p>{participant.ticketNumber}</p></div>
                  </>
                  )
                })}
            </div>       
            <div className="loader">
            <Loader
              visible={loading}
              type="TailSpin"
              color="#00ffa3"
              height={100}
              width={100}
            />    
            </div>
        </>      
        }
        </section>     
      </>
      : null
      }     
    </div>  
  )
}

export default LotteryPage;

import React, { useState, useEffect, useRef } from "react";
import {useParams} from "react-router-dom";
import "../../App.css";
import "../Room.css"
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Countdown from "react-countdown";
import { doc, getDoc, updateDoc, getFirestore, increment, onSnapshot, arrayUnion} from "firebase/firestore"; 
import {
  Connection,
  Transaction,
  clusterApiUrl,
  SystemProgram,
} from "@solana/web3.js";
import Navbar from "../Navbar.js";
import Loader from "react-loader-spinner";
import moment from "moment";
import Slider from "react-slick";
import ConfettiCanvas from 'react-confetti-canvas';
import ReactLuckySpinner from '../ReactLuckySpinner.js';

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <button class="slider-button left" onClick={onClick}>
      <span class="left-arrow"></span>
    </button>
  );
}

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <button class="slider-button right" onClick={onClick}>
      <span class="right-arrow"></span>
    </button>
  );
}


function Room (props) {

  let {roomNum, range, entryFee, capacity} = useParams();

  const timeout = useRef(null);
  const sendAddr = useRef(null);

  const db = getFirestore();

  const [connected, setConnected] = useState(false);
  const [logs, setLogs] = useState([""]);
  const [winner, setWinner] = useState({name: null, address: null, verified: null});
  const [resultDate, setResultDate] = useState("");
  const [userName, setUserName] = useState("");
  const [roomFill, setRoomFill] = useState(0); 
  const [reserved, setReserved] = useState(false);  
  const [reserveDisabled, setReserveDisabled] = useState(false);
  const [step2, setStep2] = useState(true); // pay entry fee
  const [step3, setStep3] = useState(false); // upload and verify nft 
  const [dropNum, setDropNum] = useState(null);
  const [loading, setLoading] = useState(false);
  const [participants, setParticipants] = useState([{name: null, address: null}]);  
  const [nfts, setNfts] = useState([]);
  const [prevWinner, setPrevWinner] = useState();
  const [registered, setRegistered] = useState(false);
  const [timerPlaying, setTimerPlaying] = useState(false);
  const [sendAddress, setSendAddress] = useState("");
  const [start, setStart] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [countdownComplete, setCountdownComplete] = useState(false);
  const [beforeFirstDrop, setbeforeFirstDrop] = useState(false);
  const [entryFeeAddress, setEntryFeeAddress] = useState("");
  const [sumOfNFTs, setSumOfNFTs] = useState(0);
  const [spinComplete, setSpinComplete] = useState(false);

  // window.onload = () => {
  //   // find spin button and click it
  //   var buttons = document.getElementsByTagName("button");
  //   Array.from(buttons).forEach(b => {
  //     if (b.textContent == "Spin") {
  //       setTimeout(function() {b.click()}, 1000);
  //     }
  //   })    
  // }

  useEffect(() => {  
    moment().isBetween(
      moment("2022-01-03T17:00:00Z"),
      moment("2022-03-18T20:00:00Z") //Change this to earlier date to reveal button.
    ) ? setbeforeFirstDrop(true) : setbeforeFirstDrop(false)
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    // onsnapshot runs once then listens to changes
    // Updates room in real time. Updates whole page's displayed info correctly.    
    const unsubscribe = onSnapshot(
      doc(db, "Room " + roomNum, "Drop " + dropNum), // get last drop in collection 
      { includeMetadataChanges: true }, 
      (doc) => {        
        if (doc.exists()) { 
          if (doc.data().winner) {                 
            setWinner(doc.data().winner);            
          } else {
            setWinner({name: null, address: null, verified: null});
          }          
          setRoomFill(doc.data().entered);
          let docParticipants = doc.data().participants;
          setParticipants(docParticipants);
          var sumOfNFTs = 0;
          docParticipants.forEach(p => {
            if (p.NFTPrice) {
              sumOfNFTs += p.NFTPrice;
            }
          });
          setSumOfNFTs(sumOfNFTs);
          setNfts(doc.data().nfts);
          setPrevWinner(doc.data().prevWinner)
          // SHOULD BE ROOM FILL BELOW BUT ACTING STRANGE
          // Changes buttons disabled or not according to database.
          if (doc.data().entered >= capacity) {
            setReserveDisabled(true)
          } else {
            // Despite no more decrements, we might change something manually.            
            setReserveDisabled(false)            
          }
        }          
      });
      
      // unsubscribe from listener 
      return () => unsubscribe();
  }, [dropNum])

  // get drop number and result date from firebase
  getDoc(doc(db, "ReleaseDate/NextRelease")).then((docSnap) => {
      const dropDate = docSnap.data().nextDropDate.toDate()                            
      setResultDate(dropDate);
      setDropNum(docSnap.data().dropNum); // current drop number
      setSendAddress(docSnap.data().sendAddresses[roomNum]); 
      setEntryFeeAddress(docSnap.data().sendAddresses[roomNum]);
    })        

  const NETWORK = clusterApiUrl("mainnet-beta");
  const addLog = (log: string) => setLogs([...logs, log]);
  const connection = new Connection(NETWORK);
  //const provider = getProvider();  

  async function finishReservation() {
    if (userName.trim() === "") {
      alert("Please enter a username for this room");
    } else if (userName.trim().length > 20) {
      alert("Username must be 20 characters or less")
    } else {
      alert("You have been successfully entered into this drop! Now you just have to wait for your NFT to be verified by our team.")
      setTimerPlaying(false);   
    }
  }

  const getProvider = (): PhantomProvider | undefined => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    } else if ("solflare" in window) {
      const provider = window.solflare;
      if (provider.isSolflare) {
        return provider;
      }
    }
    // window.open("https://phantom.app/", "_blank");
  };

  const createTransferTransaction = async () => {
    if (!getProvider().publicKey) {
      return;
    }
    let transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: getProvider().publicKey,
        toPubkey: entryFeeAddress, //provider.publicKey,
        // 1 lamport is 0.000000001 sol, 1000000 lamports is 0.16 cents
        lamports: entryFee * 1000000000
      })
    );
    transaction.feePayer = getProvider().publicKey;
    addLog("Getting recent blockhash");
    transaction.recentBlockhash = (
      await connection.getRecentBlockhash()
    ).blockhash;
    return transaction;
  };

  const sendTransaction = async () => {        
    if (!isNaN(capacity) && roomFill >= capacity) {      
      alert("Room is full")
    } else {
      setTimerPlaying(true);
      const transaction = await createTransferTransaction();

      if (transaction) {
        try {

          setLoading(true);
          let signed = await getProvider().signTransaction(transaction);
          // addLog("Got signature, submitting transaction");
          let signature = await connection.sendRawTransaction(signed.serialize());
          // addLog(
          //   "Submitted transaction " + signature + ", awaiting confirmation"
          // );
          
          //Confirming transaction.        
          await connection.confirmTransaction(signature).then(() => {
            setLoading(false);
            // when entry fee is confirmed, write wallet address to firestore
            updateDoc(doc(db, "Room " + roomNum, "Drop " + dropNum), {
              participants: arrayUnion({name: userName, address: getProvider().publicKey.toBase58(), verified: false, NFTPrice: 0}),
              entered: increment(1) // increment number of people in the room
            }, {merge: true}).then(() => {
              setRegistered(true);
            }) 
          }).catch(() => {
            setLoading(false);
          });        
          
          console.log("Wallet Address: ", getProvider().publicKey.toBase58())
          // addLog("Transaction " + signature + " confirmed");
          // transaction confirmed - add the wallet address to firestore room 
          // Makes pay button disabled.
          setStep2(false);
          setStep3(true);
        } catch (err) {
          console.warn(err);
          alert("Transaction Failed.")
          setLoading(false);
          setStep3(false);
          setStep2(true);
        }
      }
    }
  };

  const requestTransaction = () => {
    if (userName.trim() === "") {
      alert("Please enter a username for this room");
    } else if (userName.trim().length > 20) {
      alert("Username must be 20 characters or less")
    } else {
      if (window.solana.isConnected) {
        sendTransaction();
      } else {
        //If provider is phantom, log them in to wallet, or send them to phantom website if they don't have an account.
        if ("solana" in window) {
          const provider = window.solana;
          if (provider.isPhantom) {
            //Connects to Phantom wallet.
            window.solana.connect();
            //window.solana.connect({ onlyIfTrusted: true });
            window.solana.on("connect", () => sendTransaction());
          } else {
            window.open("https://phantom.app/", "_blank");
          }
        } else if ("solflare" in window) {
          const provider = window.solflare;
          if (provider.isSolflare) {
            //Connects to Solflare wallet.
            window.solflare.connect();
            window.solflare.on("connect", () => sendTransaction());
          } else {
            window.open("https://solflare.com", "_blank");
          }          
        }
      }
    }
  };

  const tryToReserve = () => {
    if (!isNaN(capacity) && roomFill >= capacity) {
      setReserveDisabled(true);
      alert("Sorry, the room is full! Come back after the next drop to try again.")
    } else {
      setReserved(true)
    }
  }

  const scrollDown = (section) => {
    window.scrollTo({
      top:
        document.getElementById(section).getBoundingClientRect().top +
        window.scrollY,
      left: 0,
      behavior: "smooth",
    });
  };  

  const timeExpired = () => {
    alert("Time limit exceeded");    
    window.history.back();
  }

  return (
    <div className="room-page">
      <Navbar page={"room"}/>            
      <h1 className="room_title">
      {range + " SOL ROOM (" + roomFill + "/" + (isNaN(capacity) ? "∞" : capacity) + ")"}
      </h1>
      {winner.name == null ? 
      <>
      {isMobile === true ? null : 
      <div className="reg-timeout">        
        <CountdownCircleTimer
        className="countdown_timer"
        size={200}
        strokeWidth={6}
        isPlaying={timerPlaying}
        isLinearGradient={true}
        duration={480} 
        onComplete={() => timeExpired()}
        colors={[["#00FFA3"]]}           
        >
        {({ elapsedTime }) =>
          <div className="time-wrapper">
            <div className="time">{8-Math.ceil((elapsedTime/60))}m {elapsedTime > 0 ? Math.floor(60-(elapsedTime % 60)) : "0"}s</div>
          </div>        
        }
        </CountdownCircleTimer>
      </div>}

      {/* {beforeFirstDrop ? 
      
      <>
      {<div className="latest-winner-in-room">
        <div className="latest-winner-contents">
          <h3>
            WHITELIST
          </h3>
          <hr className="winner-separator"/>
          <h3>
            CHECK DISCORD
          </h3>        
          <p>8 spots left</p>
        </div>
      </div>}
      </>
      : 
      <> */}
      {prevWinner == null ? null :
      <div className="latest-winner-in-room">
        <div className="latest-winner-contents">
          <h3>
            PREVIOUS<br/>WINNER
          </h3>
          <hr className="winner-separator"/>
          <h3>
            {prevWinner.name.toUpperCase()}
          </h3>        
          <p>({prevWinner.address.substring(0, 4)}...{prevWinner.address.slice(-4)})</p>
        </div>
      </div>}

      <section className="room-contents">

        {beforeFirstDrop ? 
        <>
          <h3>ENTRIES OPEN ON FRI, 18 MARCH, 8PM UTC</h3>
          <Countdown className="countdown" date={new Date("2022-03-18T20:00:00Z")}/>
        </>
        :
        <>
        {/* Should be NEXT WINNER and resultDate inside moment() */}
        <h3>NEXT WINNER: {moment(resultDate).format("ddd, hA").toUpperCase()}</h3>
        {countdownComplete === true ?
        <Loader type="TailSpin" color="#00ffa3" height={100} width={100}/>                
        :
        <Countdown className="countdown" date={new Date(resultDate)} onComplete={() => setCountdownComplete(true)} />}       
        </>
        }   
        
        <div className="inputs">
          {isMobile === true ? 
          <h3 className="mobile-room-message">You currently cannot reserve a spot on mobile, as the Phantom wallet extension is not available on iOS or Android</h3> 
          : registered ? (
            <div className="registration-complete">
              <h1>Congratulations!</h1>
              <h3>You have been entered into the room but your NFT will take up to 1 hour to be verified.</h3>
              <h3>Please come back later to check if your NFT has been verified.</h3>            
              <h3>If your NFT doesn't get verified, your entry fee will be refunded and your NFT will be returned.</h3>
            </div>
          )
          : 
          reserved ? (
            <>
            <p className="to-note">Do not refresh the page after submitting your entry fee</p>
            <div className="form-item"><span className="form-number">1</span>
            <p>Enter a username so you can identify yourself if you win.</p>
              <input minLength="2" maxLength="20" type="text" title="Must be 2-20 characters long" onChange={e => setUserName(e.target.value)} className="form-input" placeholder="Username"></input>      
            </div>
            <div className="form-item"><span className="form-number">2</span>
            <p>Submit the entry fee for this room.</p>
              <button className="form-button" onClick={() => requestTransaction()}>
                {step3 === true && !step2 ? "ENTRY FEE PAYED" : "PAY " + entryFee +  " SOL"}
              </button>  
            </div>
            <div className="form-item"><span className="form-number">3</span>
            <p>Send your NFT to the following wallet address to be verified:</p>
            <p>(Your NFT should be worth between {range.toLowerCase()} Solana)</p>
            <span className="copy" onClick={() => navigator.clipboard.writeText(sendAddress)}>
              <div ref={sendAddr}>{sendAddress}<i className="far fa-copy"></i></div>
            </span>
            </div>
            <div className="form-item"><span className="form-number">4</span>
            <p>Complete your reservation by clicking the button below.</p>
              <button className="form-button" onClick={() => finishReservation()}>COMPLETE RESERVATION</button>          
            </div>
            </>
          ) : 
          reserveDisabled === true ? null :  
          <button disabled={reserveDisabled} className="form-button reserve" onClick={() => tryToReserve()}>RESERVE A SPOT</button>  
          }
        
        </div> 
        {registered ? null : 
          <h3>
            PRIZE: UP TO {capacity} NFTs WORTH {range.toLowerCase()} SOL
          </h3>
        }                   
        {(participants.length === 1 && participants[0].name === null || participants.length === 0) ? 
        <h3 className="no-participants">No participants yet</h3>
        : 
        <>
        <h2 className="participants-header">PARTICIPANTS</h2>
        <div className="participants-grid">              
          <div className="grid-header left-header">USERNAME</div>
          <div className="grid-header">WALLET ADDRESS</div>
          <div className="grid-header">WIN PROBABILITY</div>
          <div className="grid-header right-header">VERIFIED</div>
          {participants.map((participant, i) => {            
            return(
              <>
              <div className={i == participants.length -1 ? "grid-item left-item" : "grid-item"}><p>{participant.name}</p></div>
              <div className="grid-item"><p>{participant.address}</p></div>
              <div className="grid-item"><p>{Math.floor((participant.NFTPrice / sumOfNFTs) * 100)}%</p></div>
              <div className={i == participants.length -1 ? "grid-item right-item" : "grid-item"}><p>{participant.verified === true ? <span>&#10003;</span> : "pending..."}</p></div>
              </>
            )
          })}
        </div>  
        </>      
        }
        {nfts && nfts.length > 0 ? 
        <span onClick={() => scrollDown("nft-content")} className="pointer">
          <p>NFTs to be won</p>
          <span className="down-arrow"></span>
        </span> : null}
        </section>
        {nfts && nfts.length > 0 ?                  
        <section className="nft-content" id="nft-content">
          <h2>NFT'S TO BE WON</h2>
          <div className="slider-container nft-slides"> {/*slider to show all nft's won*/}
            <Slider
            dots={true}
            dotsClass="slick-dots"        
            speed={500}
            slidesToShow={nfts.length > 2 ? 3 : 1}
            slidesToScroll={1}            
            prevArrow={<PrevArrow />}
            nextArrow={<NextArrow />}
            >
              {nfts && nfts.map((nft, index) => {
                return (
                  <a key={nft.link} href={nft.link} target="_blank" className="nft-link">
                    <img className="nft-image" src={nft.image} alt={nft.link} title={nft.link}/>
                  </a>
                )
              })}
            </Slider>        
          </div>
        </section> 
        :
        null // no nfts to be shown
        }      
      <div className="loader">
        <Loader
          visible={loading}
          type="TailSpin"
          color="#00ffa3"
          height={100}
          width={100}
        />      
      </div>  
      </>
      : 
      <div className="winner"> 
        {spinComplete == true ?
        // <ReactLuckySpinner 
        // // items={[...participants].map(p => p.name)}
        // segments={["1", "2", "3"]}
        // segColors={["#000", "#00FFA3", "#000"]}
        // onFinished={(winner) => console.log(winner)}
        // // onSpinComplete={() => setSpinComplete(true)} // after spinner completes, show the winner
        // />
        null
        :      
        <>
        <div className="confetti">
        <ConfettiCanvas 
        colors={[["#00ffa3", "#fff"], ["#00ffa2", "#fff"], ["#00ffa3", "#fff"]]} 
        duration={0.09}
        paperCount={70}
        ribbonCount={0}/>
        </div>
        <p>WINNER</p><hr/>
        <p>{winner.name} <br/>
          <span className="smaller_text"> ({winner.address})</span>
          <p className="smaller_text">Next drop: <Countdown className="countdown smaller_text" date={new Date(new Date(resultDate).getTime() + 60 * 60 * 24 * 1000)} /></p>
        </p>
        <span onClick={() => scrollDown("nft-content")} className="pointer">
          <p className="smaller_text">SEE NFTs</p>
          <span class="down-arrow"></span>
        </span>
        <section className="nft-content" id="nft-content">
          <h2>NFT WINNINGS</h2>
          <div className="slider-container nft-slides"> {/*slider to show all nft's won*/}
            <Slider
            dots={true}
            dotsClass="slick-dots"        
            speed={500}
            slidesToShow={nfts.length > 2 ? 3 : 1}
            slidesToScroll={1}            
            prevArrow={<PrevArrow />}
            nextArrow={<NextArrow />}
            >
              {nfts && nfts.map((nft, index) => {
                return (
                  <a key={nft.link} href={nft.link} target="_blank" className="nft-link">
                    <img className="nft-image" src={nft.image} alt={nft.link} title={nft.link}/>
                  </a>
                )
              })}
            </Slider>        
          </div>
        </section>
        </>
        } 
      </div>
      }     
    </div>  
  )
}

export default Room;
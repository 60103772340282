import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import Help from './components/Help';
import Room from './components/pages/Room';
import Maintenance from './components/Maintenance';
// import Mint from './components/pages/Mint';
import Disclaimer from './components/pages/Disclaimer';
import Lottery from './components/pages/Lottery';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAAtwasQvfp8oS48y1i_Lj0dkeBIkl_RgA",
  authDomain: "solswap-cee8b.firebaseapp.com",
  projectId: "solswap-cee8b",
  storageBucket: "solswap-cee8b.appspot.com",
  messagingSenderId: "124965626993",
  appId: "1:124965626993:web:af7687240246926ae0e7d0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/:roomNum/:range/:entryFee/:capacity' children={<Room />} />          
          <Route path='/disclaimer' component={Disclaimer} />
          <Route path='/lottery' component={Lottery} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
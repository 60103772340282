import React from 'react';
import '../../App.css';

function Disclaimer() {
    return (
      <div className="privacy-page home">
        <h1>SOLSWAP DISCLAIMER</h1>
        <p>Solswap is a website for gambling NFTs built on the Solana network.</p>
        <p>By using the website you acknowledge and agree that there is a good chance you will not derive profits from putting your NFTs on the line, and will likely lose them due to probability.</p>
        <h3>FEES AND PAYMENT</h3>
        <p>Any transaction initialized on the website will be conducted solely through the Solana network. The platform fees are set by the Solswap team and are bound to change over time.</p>
        <p>We have no control over the Solana network fees, nor do we have the ability to reverse any transactions unless there is good reason to do so. You agree that we will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions pertaining to the platform, or any other transactions that are conducted via the Solana network.</p>
        <p>The Solana network requires the payment of a transaction fee for every transaction that occurs on the Solana network. This fee funds the network of computers that run the Solana network. This means that you will need to pay any such fees for each transaction.</p>
      </div>
    )
}

export default Disclaimer;

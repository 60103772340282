import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import {
  doc,
  setDoc,
  collection,
  getDoc,
  getFirestore,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import Loader from "react-loader-spinner";
import Navbar from "./Navbar.js";
import Help from "./Help.js";
import RoadMap from "./RoadMap.js";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button className="slider-button right" onClick={onClick}>
      <span className="right-arrow"></span>
    </button>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button className="slider-button left" onClick={onClick}>
      <span className="left-arrow"></span>
    </button>
  );
}

function HomePage() {
  const ranges = [
    "0 - 0.1",
    "0 - 0.1",
    "0.1 - 0.5",
    "0.1 - 0.5",
    "0.5 - 1",
    "0.5 - 1",
    "1 - 2",
    "1 - 2",
    "2+",
    "2+",
  ];
  const numRooms = 10;
  const [rooms, setRooms] = useState({});
  const [dropNum, setDropNum] = useState(null);
  const [narrowScreen, setNarrowScreen] = useState(
    window.matchMedia("(max-width: 1150px)").matches
  );

  window.addEventListener("resize", () => {
    setNarrowScreen(window.matchMedia("(max-width: 900px)").matches);
  });

  const db = getFirestore();

  const setRoomSlides = (index) => {
    if (index) {
      const roomNum = index + 1;
      const document = doc(db, "Room " + roomNum, "Drop " + dropNum);
      // index is the current center slide
      getDoc(document).then((doc) => {
        if (doc.exists()) {
          let newRoom = {};
          newRoom.range = ranges[index];
          newRoom.capacity = index == 1 ? "Unlimited" : index % 2 == 0 ? 3 : 10;
          newRoom.solEntryFee = 0.1;
          newRoom.prevWinner = doc.data().prevWinner;
          newRoom.fill = doc.data().entered;
          setRooms((prevState) => ({ ...prevState, [roomNum]: newRoom }));
        } else {
          if (dropNum != null) {
            setDoc(document, {
              entered: 0,
              participants: [],
              prevWinner: null,
              nfts: [],
            });
          }
        }
      });
    } else {
      // get all the rooms from firebase and set rooms state
      for (let j = 0; j < numRooms; j++) {
        const roomNum = j + 1;
        getDoc(doc(db, "Room " + roomNum, "Drop " + dropNum)).then((doc) => {
          if (doc.exists()) {
            let newRoom = {};
            newRoom.range = ranges[j];
            newRoom.capacity = j == 2 ? "Unlimited" : j % 2 == 0 ? 10 : 3;
            newRoom.solEntryFee = 0.1;
            newRoom.prevWinner = doc.data().prevWinner;
            newRoom.fill = doc.data().entered;
            setRooms((prevState) => ({ ...prevState, [roomNum]: newRoom }));
          }
        });
      }
    }
  };

  useEffect(() => {
    setRoomSlides(); // initialise all room slides
    const unsubs = []; // array for all room listeners

    // create a listener for each room
    for (let i = 0; i < numRooms; i++) {
      const roomNum = i + 1;
      const document = doc(db, "Room " + roomNum, "Drop " + dropNum);
      getDoc(document).then((doc) => {
        if (!doc.exists()) {
          setDoc(document, { entered: 0, participants: [] });
        }
      });
      const unsub = onSnapshot(document, (doc) => {
        setRoomSlides(i);
        unsubs.push(unsub);
      });
    }
    // unsubscribe from all listeners
    unsubs.forEach((unsub) => unsub());
    // clear unsubs list
    unsubs.length = 0;
  }, [dropNum]);

  // get drop number and result date from firebase - triggers useeffect
  getDoc(doc(db, "ReleaseDate/NextRelease")).then((docSnap) => {
    setDropNum(docSnap.data().dropNum); // current drop number
  });

  const scrollDown = (section) => {
    window.scrollTo({
      top:
        document.getElementById(section).getBoundingClientRect().top +
        window.scrollY,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="home">
      <Navbar page={"home"} />
      <section className="description" id="hero">
        <div className="description-box">
          <h2 className="own_solana_n">Own Solana NFTs?</h2>
          <ul className="list">
            <li className="list_item">
              Solswap is for those looking to gamble their NFTs in the most
              thrilling way...
            </li>
            <li className="list_item">
              The rooms below host weekly drops where you can gamble your NFTs
              for a fair chance to win all the other NFTs gambled in that room.
            </li>
            <li className="list_item">
              Each room has a different price range so don't worry, you only
              compete with those who have put in{" "}
              <a href="#verification-process">verified</a> NFTs worth a similar
              amount.
            </li>
            {/* <li>
              The probability of winning is the same for everyone in the room.
            </li> */}
            <li className="list_item">
              After you enter a room, your chance of winning will be weighted
              according to the price of your NFT (more valuable NFTs will result
              in higher chances of winning).
            </li>
            <li className="list_item">
              Be quick, each room has a maximum capacity!
            </li>
          </ul>
        </div>
        <span onClick={() => scrollDown("rooms")} className="pointer">
          <p>ENTER</p>
          <span className="down-arrow"></span>
        </span>
      </section>
      <section className="rooms" id="rooms">
        <h1>ROOMS</h1>
        <div className="slider-container">
          {rooms.length == 0 ? (
            <Loader
              visible
              type="Bars"
              color="#FFFFFF"
              height={100}
              width={100}
            />
          ) : (
            <Slider
              dots={true}
              dotsClass="slick-dots"
              customPaging={(i) => {
                return <div>{i + 1}</div>;
              }}
              infinite={true}
              speed={500}
              slidesToShow={narrowScreen ? 1 : 3}
              slidesToScroll={1}
              prevArrow={<PrevArrow />}
              nextArrow={<NextArrow />}
              centerMode={true}
              centerPadding="60px"
              className="center"
            >
              {Object.values(rooms).map((room, index) => {
                const roomNum = index + 1;
                const roomUrl =
                  "" +
                  roomNum +
                  "/" +
                  room.range +
                  "/" +
                  room.solEntryFee +
                  "/" +
                  room.capacity;

                return (
                  <a className="room-box" href={roomUrl} key={index}>
                    <h1 className="swap_colour">Room {index + 1}</h1>
                    <div className="room-box-contents">
                      <h3>
                        <div className="room_label">NFT range:</div>
                        <span className="swap_colour room_value">
                          {room.range} SOL
                        </span>
                      </h3>
                      <h3>
                        <div className="room_label">Remaining spaces:</div>
                        <span className="swap_colour room_value">
                          {isNaN(room.capacity)
                            ? "Unlimited"
                            : room.capacity - room.fill + "/" + room.capacity}
                        </span>
                      </h3>
                      <h3>
                        <div className="room_label">Prize: </div>
                        <span className="swap_colour room_value">
                          {room.capacity} NFT's
                        </span>
                      </h3>
                      {/* <h3>
                        <div className="room_label">Chance of winning:</div> 
                        <span className="swap_colour room_value">{+(Math.round(100 / room.capacity + "e+2") + "e-2")} % </span>
                      </h3> */}
                      {room.prevWinner == null ? null : (
                        <h3 className="latest_winner">
                          Previous Winner <hr />
                          <span className="swap_colour">
                            {room.prevWinner.name} <br />
                            <span className="normal-text">
                              ({room.prevWinner.address})
                            </span>
                          </span>
                        </h3>
                      )}
                    </div>
                  </a>
                );
              })}
            </Slider>
          )}
        </div>
        <span onClick={() => scrollDown("verification")} className="pointer">
          <p>VERIFICATION</p>
          <span className="down-arrow"></span>
        </span>
      </section>
      <section className="verification_process" id="verification">
        <h1>HOW DO WE VERIFY YOUR NFTs?</h1>
        <div className="description-box verification">
          <h3>
            Through the help of our team and our API, we verify the value of
            your NFTs using the following where possible:
          </h3>
          <ul className="list">
            <li>Previous Transaction Price</li>
            <li>Collection Floor Price</li>
            <li>Collection Rank</li>
            <li>Rarity</li>
            <li>Utility</li>
            <li>Minting Price</li>
          </ul>
        </div>
        <span onClick={() => scrollDown("roadmap")} className="pointer">
          <p>ROADMAP</p>
          <span className="down-arrow"></span>
        </span>
      </section>
      <RoadMap />
      <Help />
    </div>
  );
}

export default HomePage;

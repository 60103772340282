import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import phantom_icon from "../images/phantom-icon.png";
import solflare_icon from "../images/solflare-icon.png";
import solanaLogo from "../images/solanaLogo.svg";

function Navbar(props) {
  const [click, setClick] = useState(false);
  const [connected, setConnected] = useState(false);
  const [narrowScreen, setNarrowScreen] = useState(window.matchMedia("(max-width: 900px)").matches);

  window.addEventListener('resize', () => {
    setNarrowScreen(window.matchMedia("(max-width: 900px)").matches)
  })

  useEffect(() => {
    // Will either automatically connect to Phantom or Solflare, or do nothing.
    // window.addEventListener('load', () => {
    //   connectWallet();
    // })
  }, []);

  //If provider is phantom, log them in to wallet, or send them to phantom website if they don't have an account.
  const connectWallet = () => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        //Connects to Phantom wallet.
        window.solana.connect();
        window.solana.on("connect", () => setConnected(true));
      } else {
        window.open("https://phantom.app/", "_blank");
      }
    } else if ("solflare" in window) {
      const provider = window.solflare;
      if (provider.isSolflare) {
        //Connects to Solflare wallet.
        window.solflare.connect();
        window.solflare.on("connect", () => setConnected(true));
      } else {
        window.open("https://solflare.com/", "_blank");
      }
    }    
  };

  //Disconnects phantom wallet from site.
  const disconnectWallet = () => {
    if ("solana" in window) {
      window.solana.disconnect();
      window.solana.on('disconnect', () => setConnected(false));
    } else if ("solflare" in window) {
      window.solflare.disconnect();
      window.solana.on('disconnect', () => setConnected(false));
    }
  };

  const scrollTo = (section) => {
    window.scrollTo({
      top:
        document.getElementById(section).getBoundingClientRect().top +
        window.scrollY,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={props.footer === true ? "navbar footer" : "navbar"}>
            
      <div className={!narrowScreen ? "navbar-left" : "navbar-center"}>
        <a className={props.footer == true ? "footer-home-button": "home-button"} href="/">
          <b>
          <span className="sol">SOL</span><span className="swap">SWAP</span>
          </b>
        </a>      
        {props.page == "home" ? 
        <p className="beta">
        beta
        </p> : null}             
      </div>      
      {props.footer === true ? 
      <div className="navbar-center">
        <div className="solana-img">     
          <span className="powered-by"><i>POWERED BY</i></span>
          <img className="solana-logo" src={solanaLogo} /> 
        </div>
      </div> : null}           
      <div className="navbar-right">
        {props.page == "home" && props.footer == null ? 
        <>
        <div className="navbar-item">
          <a href="#rooms">ROOMS</a>
        </div>           
        <div className="navbar-item">
          <a href="#verification">VERIFICATION</a>
        </div>                              
        <div className="navbar-item">
          <a href="#roadmap">ROADMAP</a>
        </div>        
        <div className="navbar-item">
          <a href="#faq">FAQ</a>
        </div>
        <div className="navbar-item">
          <a href="/disclaimer">DISCLAIMER</a>
        </div>
        <div className="navbar-item">
          <a className="navbar-button lottery-button" href="/lottery">LOTTERY</a>
        </div>           
        <div className="navbar-item">
          <a href="https://discord.gg/DZWgW43TZc">
            <i className="fab fa-discord fa-2x discnav"></i>
          </a>
        </div>
        <div className="navbar-item">
          <a href="https://twitter.com/Solswapteam">
            <i className="fab fa-twitter fa-2x twitnav"></i>
          </a>
        </div>   
        </>       
        : props.footer === true ? 
        <div className="navbar-item">
          <span onClick={() => window.scrollTo({top:0,left:0,behavior:"smooth",})} 
            className="pointer">
            <span className="up-arrow"></span>
            <p>BACK TO TOP</p>
          </span>
        </div>
        : null}  

        {props.page == "home" ? 
        null
        :           
        <div className="navbar-item" style={{display: "flex", gap: "50px", alignItems: "center"}}>     
          {props.page == "room" || props.page == "lottery" ?
            <div className="navbar-item">
              <a href="https://discord.gg/DZWgW43TZc">
                <i className="fab fa-discord fa-2x discnav"></i>
              </a>
            </div>  : null 
          }
          {props.page == "room" || props.page == "lottery" ?        
            connected ? (
              <button className="navbar-button" onClick={() => disconnectWallet()}>
                {/* <img className="wallet-icon" src={phantom_icon} /><img className="wallet-icon" src={solflare_icon} />*/}
                DISCONNECT 
              </button>
            ) : (
              <button className="navbar-button" onClick={() => connectWallet()}>
                {/* <img className="wallet-icon" src={phantom_icon} /><img className="wallet-icon" src={solflare_icon} />
                */}
                Connect Wallet 
              </button>
            )
          : 
          null
          }
        </div>
        }
      </div>
    </div>    
  );
}

export default Navbar;

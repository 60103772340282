import LotteryPage from "../LotteryPage.js";

function Lottery () {

    return(
        <>
        <LotteryPage />
        </>
    )
}

export default Lottery;
import React, { useState } from "react";
import "../App.css";
import "./RoadMap.css";

function RoadMap() {

  const scrollDown = (section) => {
    window.scrollTo({
      top:
        document.getElementById(section).getBoundingClientRect().top +
        window.scrollY,
      left: 0,
      behavior: "smooth",
    });
  };    
    
  return (
    <section className="roadmap" id="roadmap">
      <h1>ROADMAP</h1>
        <ol>
            <li className="left_item">
                <div>                    
                    Grow Solswap community
                    <p className="desc">February 2022</p>
                    <p className="desc">Advertising and promoting Solswap to gain an initial following.</p>
                </div>
            </li>
            <li className="right_item">
                <div>                    
                    First room opening
                    <p className="desc">March 2022</p>
                    <p className="desc">Opening our rooms for the first time to allow the Solswap community to start putting their NFTs on the line.</p>
                </div>
            </li>
            <li className="left_item">
                <div>
                    Solswap lottery ticket NFT launch
                    <p className="desc">April 2022</p>
                    <p className="desc">Mint our own NFTs where you choose the lottery number that appears on your ticket.</p>
                </div>
            </li>
            <li className="right_item">
                <div>
                    Begin monthly lotteries
                    <p className="desc">May 2022</p>
                    <p className="desc">We begin our monthly lotteries, with the selection live-streamed.</p>
                </div>
                {/* <div>                    
                    Solswap lottery launch
                    <p className="desc">Allow people to buy tickets for the Solswap lottery using their Solswap tokens.</p>
                </div> */}
            </li>
            <li className="left_item">
                <div>          
                    Utility token launch
                    <p className="desc">July 2022</p>
                    <p className="desc">Launch a Solswap token which can be used to enter rooms, join the lottery and much more.</p>
                </div>
            </li>
            <li></li>
        </ol>
        <span onClick={() => scrollDown("faq")} className="pointer">
          <p>FAQs</p>
          <span className="down-arrow"></span>
        </span>            
    </section>
  );
}

export default RoadMap;

import React, { useState } from "react";
import "../App.css";

function Help() {
  const [question, setQuestion] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [senderDiscord, setSenderDiscord] = useState("");
  const [faqs, setFaqs] = useState([
    {
      question: "When is the first drop?",
      answer:
        "The first drop will take place on Friday the 25th of March at 8pm UTC. Entries for this drop will open on Friday the 18th of March also at 8pm UTC.",
    },
    {
      question: "How often will drops be?",
      answer:
        "After the first drop, they will continue to take place every Friday at 8pm UTC.",
    },
    {
      question: "Can I enter the drop more than once?",
      answer:
        "Yes. Provided there are still places left in the room, you may re-enter it and sign up as many times as you would like. ",
    },
    {
      question: "How long does verification take?",
      answer:
        "Currently a maximum of 1 hour. We are working on bringing this down to less than 5 minutes in the near future.",
    },
    {
      question: "What happens if your NFT doesn’t get verified?",
      answer:
        "You will get both your NFT and entry fee back within 1 hour of submission.",
    },
    {
      question: "Which room do I enter if my NFT is on the edge of two ranges?",
      answer:
        "You can choose either room!"
    },
    {
      question: "If I win, when and where do I receive my NFTs?",
      answer:
        "Within 30 minutes after the drop, all of your NFTs will have arrived in the wallet you sent the entry fee and NFT from.",
    },
    // {
    //   question: "Why has my NFT not been verified?",
    //   answer:
    //     "It may be because your NFTs last purchase price was not in the right price range for the room you entered, or because your NFT was a fake.",
    //p },
    {
      question: "What wallets do you support?",
      answer:
        "We currently only support Phantom Wallet and Solflare. We are working on adding support for other Solana wallets.",
    },
    {
      question: "What happens if the room isn't full at the drop time?",
      answer:
        "If the room is over 50% its capacity, the drop will still occur, but with a higher expected win % due to less participants. If the room is under 50% capacity, the drop will not happen and the current participants and NFTs will be carried over to the following week's drop (with no additional fees).", // The reason for this is that we think it would be unreasonable to charge an entry fee for a drop with less potential rewards than expected.
    },
    {
      question:
        "What happens if I am the only participant at drop time?",
      answer:
        "You will get both your NFT and entry fee back at the time of the drop.",
    },
  ]);

  const sendMessage = () => {
    let discord = "";
    // send discord message with the question
  };

  const changeSelectedQuestion = (index) => {
    if (selectedQuestion === index) {
      setSelectedQuestion(null);
    } else {
      setSelectedQuestion(index);
    }
  };

  return (
    <section className="faq" id="faq">
      <h1>FAQs</h1>
      <div className="faqs">
        {faqs.map((q, index) => {
          return (
            <button
              key={index}
              class={
                selectedQuestion == index ? "faq-item selected" : "faq-item"
              }
              onClick={() => changeSelectedQuestion(index)}
            >
              <span className="shine">
                <span
                  className={
                    selectedQuestion === index
                      ? "faq-up-arrow faq-left"
                      : "faq-down-arrow faq-left"
                  }
                ></span>
                <h3 className="question">{q.question}</h3>
                <span
                  className={
                    selectedQuestion === index
                      ? "faq-up-arrow faq-right"
                      : "faq-down-arrow faq-right"
                  }
                ></span>
                <h3 className="answer">{q.answer}</h3>
              </span>
            </button>
          );
        })}
      </div>
      <h3 style={{ fontWeight: "normal" }}>
        If you have any other questions, please get in touch!
      </h3>
      <div className="get-in-touch-links">
        <a href="https://discord.gg/DZWgW43TZc">
          <i className="fab fa-discord fa-2x discnav"></i>
        </a>
        <a href="https://twitter.com/Solswaplive">
          <i className="fab fa-twitter fa-2x twitnav"></i>
        </a>
      </div>
      {/* <h2>GET IN TOUCH</h2>
            <input className="form-input" placeholder="Discord Username" onChange={e => setSenderDiscord(e.target.value)}></input>
            <textarea className="form-textarea" placeholder="Ask a question..." onChange={e => setQuestion(e.target.value)}>            
            </textarea>
            <button className="form-button" onClick={() => sendMessage()}>Submit question</button> */}
    </section>
  );
}

export default Help;
